@media (min-width: 768px) {
    // sidebar
    #sidebar-wrapper {
        margin-left: 0;
    }
    .page-content-wrapper {
        min-width: 0;
        width: 100%;
    }
    #wrapper.toggled #sidebar-wrapper {
        margin-left: -15rem;
    }
}

@media (max-width: 768px) {
    #sidebar-wrapper {
        width: 220px;
    }
    .vertical-net {
        display: none
    }
    body, html {
        overflow-x: hidden !important;
    }
    // navbar
    .navbar-main .container-fluid {
        justify-content: end;
        padding: 0px;
        .navbar {
            margin-top: 0px !important;
        }
        .navbar-toggler {
            border-color: white;
            margin-right: 10px;
            span {
                stroke: #fff;
            }
        }
        .navbar-brand {
            display: block !important;
            margin-left: 10px;
        }
        .navbar-brand2 {
            display: none !important;
        }
        .navbar-collapse {
            background-color: $app-gray;
            -moz-transition: height .01s;
            -ms-transition: height .01s;
            -o-transition: height .01s;
            -webkit-transition: height .01s;
            transition: height .01s;
            height: auto;
        }
        .nav-item-firts {
            margin-top: 20px;
        }
        .dropdown__container .nav-link {
            padding-left: 5px;
        }
    }
    // searcher
    .app-searcher__container {
        width: 90% !important;
        form {
            .col {
                padding-top: 1rem;
                text-align: center;
                button span {
                    display: inline !important;
                }
            }
        }
    }
    // why us
    .why-us h2::before, .why-us h2::after {
        content: none;
    }
    // miami
    .miami h2::before, .miami h2::after {
        content: none;
    }
    .owl-miami {
        padding: unset;
    }
    // footer
    .app-footer {
        height: 100% !important;
    }
    // features
    .properties-thumb2 {
        display: inline-block;
        vertical-align: top;
        position: relative;
        img {
            height: 320px !important;
        }
        &-details {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            text-align: left;
            padding: 0 0 4% 4%;
            z-index: 9;
            cursor: pointer;
            h2 {
                color: #ffffff;
                font-size: 20px;
                text-transform: uppercase;
                left: 0;
                text-align: left;
                padding: 15px 0px 8px;
                transition: all .25s ease-in-out;
                z-index: 5;
                letter-spacing: 0.06em;
                font-weight: 300;
            }
            h5 {
                text-transform: uppercase;
                letter-spacing: 0.05em;
                line-height: 1;
                color: #fff;
                margin: 0 0 18px;
                z-index: 5;
                transition: all .25s ease-in-out;
            }
            ._button {
                padding: 0px;
                color: #fff !important;
                font-size: 15px;
                font-weight: 300;
                letter-spacing: .05em;
                width: 135px;
                height: 38px;
                display: flex;
                align-items: center;
                justify-content: center;      
                border-color: $app-primary;
                background: $app-primary;  
                text-transform: uppercase;  
            }
        }
        & .color-overlay2 {
            background: linear-gradient(transparent, #000);
            opacity: .5;
        }
    }
    //
    .app-team__photo2 {
        height: calc(100px + 8.75px) !important;
    }
    .headSearch {
        input {
            width: 95vw;
        }
    }
    .team .position {
        position: relative !important;
        background-color: $gray-500 !important;
    }
    .app-img-bg {
        background-attachment:scroll !important
    }
}

@media (max-width: 768px) {
    .properties {
        &__main {
          height: 320px !important;
          position: relative;
          img {
            height: 100%;
          }
        }
        &__content {
            h3 {
              font-size: 25px;
              padding: 30px 0 9px;
            }
        }
    }
    .carousel-item {
        img {
            height: 320px !important;
        }
    }
    // advisers
    .adviser__detail__avatar {
        max-height: 450px !important;
    }
    //
    .dropdown__container {
        background-color: unset !important;
        border: none !important;
        li a {
            color: white !important;
        }
    }
}