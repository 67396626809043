.media-body {
    div {
      a {
        color: $gray-600 !important;
      }
    }
}
  
.carousel-item {
    img {
        height: 230px;
    }
    .ubication {
        font-weight: bold;
        position: absolute;
        bottom: -10px;
        left: 10px;
        color: #fff;
        text-align: center;
    }
}
  
.form-check .form-check-input {
    margin-left: 0px !important;
}
.badge-grey {
    background-color: #eff2f5;
}
  
  .btn-view-selected {
    background-color: #e2e6ea !important;
    border-radius: 5px !important;
  }
  
  .form-signin {
    width: 400px;
    margin: auto;
    margin-bottom: 50px;
    height: 70vh;
    padding: 100px 40px;
  }
  
  .avatar {
    vertical-align: middle;
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }
  
  .avatar-lg {
    height: 6rem;
    width: 6rem;
  }
  
  .nav-link {
    color: $app-gray;
    &.active {
      color: $app-primary !important;
      font-weight: bold;
    }
    &:hover {
      color: $app-gray;
      font-weight: bold;
    }
  }

.form-control.is-invalid, .was-validated .form-control:invalid,
.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
  border-color: #e74c3c;
}
.form-control.is-valid, .was-validated .form-control:valid,
.form-control.is-valid:focus, .was-validated .form-control:valid:focus {
  border-color: #07bc0c;
}

.img-banner {
  height: 450px !important;
}

.img-banner2 {
  height: 270px !important;
}

.img-avatar {
  width: 120px;
  height: 120px;
}

/* Custom Switch */
.form-check-input {
  height: 1.5em !important;
  width: 2.5em !important;
}
.form-check-input:checked {
  background-color: $app-primary !important;
  border-color: $app-primary !important;
}

.input-big {
  height: 38px;
}

.dataTables_empty {
  text-align: center;
}

.form-control {
  height: 36px;
}

input[type=password] {
  -webkit-text-security:disc;
}

.img-adviser {
  svg {
    display: none;
  }
  &:hover {
    svg {
      display: block;
    }
    .img-thumbnail {
      opacity: 0.5;
    }
  }
}

.avatar-adviser {
  max-height: 270px !important;
  width: 100%;
}

.images-properties {
  min-height: 200px;
  border: 1px dashed $gray-600;
}

#react-select-3-listbox {
  margin-top: 0px !important;
}

#react-select-3-placeholder {
  color: #212529;
}

.css-1okebmr-indicatorSeparator {
  display: none;
}

.select-advisers {
  > div {
    border: 1px solid #ced4da;
    &:hover {
      border: 1px solid #ced4da;
    }
    svg {
      color: #212529;
    }
  }
  
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.paginate_button {
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  font-weight: 400;
  line-height: 1.5;
  border-color: $primary;
  margin: 0 3px;
  a {
    text-decoration: none !important;
  }
  &.disabled {
    border-color: $gray-600;
    background-color: $gray-600;
    a {
      color: #fff;
      cursor: not-allowed;
    }
  }
  &.active {
    background-color: #0d6efd;
    border-color: #0d6efd;
    a {
      color: #fff;
    }
  }
}

.color {
  &-overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background: #000;
    opacity: .3;
    z-index: 1;
  }
  &-overlay2 {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 5;
    &:hover {
      background: linear-gradient(transparent, #000);
      opacity: .5;
      cursor: pointer;
    }
  }
  &-overlay3 {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    background: linear-gradient(transparent, #000);
    cursor: pointer;
    opacity: .2;
  }
}

.z-over {
  z-index: 5;
}

.img-property {
  height: 38vw !important;
}

.input-search {
  height: 36px;  
}

.featured-prop {
  width: 67.5%;
  position: relative;
  .property-content {
    width: 70%;
    left: 8%;
    bottom: 5%;
    position: absolute;
    z-index: 3;
    h3, p {
      color: white;
      text-transform: uppercase;
      letter-spacing: 0.15rem;
      font-weight: lighter;
    }
    h3 {
      font-size: 34px;
      padding: 30px 0 9px;
    }
    p {
      font-size: 18px;
    }
    &:hover {
      cursor: pointer;
    }
    ._button {
      font-size: 15px;
      font-weight: 300;
      letter-spacing: .05em;
      text-transform: uppercase;
      margin-top: 18px;
      padding: 0;
      transition: all .25s ease-in-out;
      border-color: #fff;
      background: transparent;
      width: 155px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      border: 1px solid;
      &:hover {
        background-color: $app-primary;
        border-color: $app-primary;
      }
    }
  }
}

.featured-content {
  width: 32.5%;
  position: relative;
  z-index: 5;
  .properties-thumbs {
    font-size: 0;
    background: #fff;
    width: 135%;
    padding: 1%;
    margin-left: -11.3vw;
    margin-top: 20%;
    position: relative;
    z-index: 4;
    .properties-thumb {
      display: inline-block;
      vertical-align: top;
      width: 48%;
      margin: 1%;
      position: relative;
      img {
        height: 12vw !important;
      }
      &-details {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: left;
        padding: 0 0 4% 4%;
        z-index: 9;
        display: none;
        cursor: pointer;
        h2 {
          color: #ffffff;
          font-size: 20px;
          text-transform: uppercase;
          left: 0;
          text-align: left;
          padding: 15px 0px 8px;
          transition: all .25s ease-in-out;
          z-index: 5;
          letter-spacing: 0.06em;
          font-weight: 300;
        }
        h5 {
          text-transform: uppercase;
          letter-spacing: 0.05em;
          line-height: 1;
          color: #fff;
          margin: 0 0 18px;
          z-index: 5;
          transition: all .25s ease-in-out;
        }
        ._button {
            padding: 0px;
            color: #fff !important;
            font-size: 15px;
            font-weight: 300;
            letter-spacing: .05em;
            width: 135px;
            height: 38px;
            display: flex;
            align-items: center;
            justify-content: center;      
            border-color: $app-primary;
            background: $app-primary;  
            text-transform: uppercase;  
        }
      }
      &:hover .properties-thumb-details {
        display: block;
      }
      &:hover .color-overlay2 {
        background: linear-gradient(transparent, #000);
        opacity: .5;
        cursor: pointer;
      }
    }
  }
  .title {
    font-weight: 300;
    font-size: 20px;
    text-transform: uppercase;
    line-height: 1.2;
    letter-spacing: 0.15em;
    margin-top: -2rem;
    width: 75%;
    h1 {
      color: $app-primary;
      span {
        display: block;
        font-size: 40px;
      }
    }
  }
}

.why-us {
  position: relative;
  padding: 100px 0;
  z-index: 1;
  color: white;
  h2 {
    font-size: 45px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 0.15em;
    margin-bottom: 54px;
    &::before {
      content: "";
      width: 10%;
      height: 1px;
      position: absolute;
      top: 28%;
      right: 0;
      margin-top: -0.5px;
      background: #fff;
    }
    &::after {
      display: block;
      content: "";
      width: 10%;
      height: 1px;
      position: absolute;
      top: 28%;
      left: 0;
      margin-top: -0.5px;
      background: #fff;
    }
  }
  &__items {
    margin-bottom: 30px;
    border-right: 1px solid white;
    text-align: center;
    &--numbers {
      font-size: 35px;
      display: inline-flex;
      justify-content: center;
      background-color: rgba(119, 159, 218, 0.6);
      min-width: 150px;
      border-radius: 5px;
    }
    &--title {
      text-transform: uppercase;
      font-size: 20px;
      letter-spacing: 3px;
      line-height: 1.7;
      font-weight: 500;
    }
  }
}

.owl-miami {
  padding: 0px 40px;
  position: relative;
  &__item {
    img {
      height: 65vh;
      width: 100%;
      transform-style: preserve-3d;
      object-fit: cover;
    }
    &--title {
      z-index: 10;
      text-align: center;
      width: 100%;
      color: white;
      top: 10%;
      text-transform: uppercase;
      letter-spacing: 0.15em;
    }
    &--description {
      z-index: 10;
      font-style: italic;
      width: 80%;
      text-align: center;
      top: 40%;
      left: 10%;
      letter-spacing: 0.15em;
      color: white;
      font-weight: 400;
    }
  }
  .owl-dots {
    position: absolute;
    bottom: 10%;
    left: 50%;
    .active span {
      background: white !important;
    }
    span {
      background: transparent !important;
      border: 1px white solid;
    }
  }
  .navowl {
    position: absolute;
    top: 45%;
    color: white;
    &.owlleft {
      left: 5%;
    }
    &.owlright {
      right: 5%;
    }
  }
}

.miami {
  position: relative;
  &__title {
    position: relative;
    background-repeat: repeat-x;
    background-image: url(data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAEklEQVR42mP8wsD4nwEIGGEMACsDA+syat96AAAAAElFTkSuQmCC);
    background-position: left 30px;
    text-align: center;
    transition: 0s;
    span {
      font-size: 45px;
      text-align: center;
      text-transform: uppercase;
      font-weight: 300;
      letter-spacing: 0.15em;
      margin-bottom: 35px;
      color: $app-primary;
      padding-bottom: 10px;
      background-color: white;
      padding: 10px;
    }
  }
}

.vertical-net {
  position: fixed;
  left: 0.5%;
  top: 35%;
  margin-top: -115px;
  z-index: 20;
  a {
    color: $app-red;
    text-align: center;
    font-size: 22px;
    text-decoration: none;
    padding-bottom: 15px;
    display: block;
    margin: 0 auto;
    svg {
      margin: 0 auto;
    }
  }
  &::before {
    width: 2px;
    height: 85px;
    margin: 0 auto 10px;
    display: block;
    content: '';
    background: $app-red;
    color: $app-red;
  }
}

.us {
  &__title {
    text-transform: uppercase;
    letter-spacing: 0.15rem;
    font-size: 2.5rem;
    color: $app-primary;
    font-weight: lighter;
  }
  &__subtitle {
    text-transform: uppercase;
    letter-spacing: 0.15rem;
  }
  &__text * {
    font-family: 'Helvetica Now', sans-serif !important;
  }
  &__img {
    height: 300px;
    object-fit: cover;
  }
}

.properties {
  &__main {
    height: 28vw;
    position: relative;
    img {
      height: 100%;
      object-fit: cover;
    }
  }
  &__content {
    position: absolute;
    left: 8%;
    bottom: 5%;
    z-index: 3;
    h3, p {
      color: white;
      text-transform: uppercase;
      letter-spacing: 0.15rem;
      font-weight: lighter;
    }
    h3 {
      font-size: 31px;
      padding: 30px 0 9px;
    }
    p {
      font-size: 18px;
    }
    &:hover {
      cursor: pointer;
    }
    ._button {
      font-size: 15px;
      font-weight: 300;
      letter-spacing: .05em;
      text-transform: uppercase;
      margin-top: 18px;
      padding: 0;
      transition: all .25s ease-in-out;
      border-color: #fff;
      background: transparent;
      width: 155px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      border: 1px solid;
      &:hover {
        background-color: $app-primary;
        border-color: $app-primary;
      }
    }
  }
}

.detail {
  &__caraousel {
    height: 35vw;
    &__detail {
      img {
        height: 30vw;
        max-width: 100%;
        object-fit: cover;
      }
    }
    &__info {
      .h3 {
        text-transform: uppercase;
        letter-spacing: 0.15rem;
        font-weight: lighter;
        color: $app-primary !important;
      }
    }
    &__description {
      letter-spacing: 0.1rem;
      font-weight: bold;
      white-space: pre-line;
    }
  }
}

.nav-link {
  text-transform: uppercase;
  letter-spacing: 0.12rem;
  font-weight: normal;
}

.adviser {
  width: 100%;
  height: 370px;
  position: relative;
  img {
    height: 100%;
    width: 100%;
    image-rendering: --webkit-optimize-contrast;
    image-rendering: crisp-edges;
  }
  &__overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: .1;
    z-index: 1;
    background: #000;
  }
  &__name {
    position: absolute;
    bottom: 10%;
    left: 0%;
    z-index: 20;
    color: white;
    font-size: 30px;
    line-height: 35px;
    letter-spacing: 0.1rem;
    display: block;
    &__prop {
      font-size: 20px;
      line-height: 25px;
      margin-bottom: 0px;
      display: none;
    }
  }
  &:hover .adviser__overlay {
    opacity: .3;
  }
  &:hover .adviser__name__prop {
    display: block;
  }
  &:hover {
    cursor: pointer;
  }
  &__detail {
    &__avatar {
      height: 100%;
      max-height: 45vw;
      max-width: 375px;
      width: 100%;
    }
    &__text {
      white-space: pre-wrap;
    }
  }
  &__property {
    height: 100%;
    max-height: 450px;
    width: 100%;
    max-width: 360px;
    margin: auto;
    img {
      object-fit: cover;
    }
  }
  &__property2 {
    height: 450px;
    width: 100%;
    max-width: 360px;
    margin: auto;
    img {
      object-fit: cover;
    }
  }
}

.dropdown {
  &__container {
    width: 130%;
    background-color: transparent;
    padding: 0;
    border: none;
    li {
      background-color: rgba(119, 159, 218, 0.7);
      margin-bottom: 2px;
    }
  }
}

.app-footer {
  height: 370px !important;
}

.img-camara {
  height: 190px;
  width: 238px;
}

.app-description {
  display: inline-block;
  //white-space: pre-wrap;
  text-align: justify;
  text-justify: inter-word;
  h2 {
    text-align: justify !important;
  }
}