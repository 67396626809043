/** WRAPPER **/

#wrapper {
    overflow-x: hidden;
}
#sidebar-wrapper {
    width: 340px;
    min-height: 100vh;
    margin-left: -15rem;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
}
#sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
}
#sidebar-wrapper .list-group {
    width: 15rem;
} 
.page-content-wrapper {
    min-width: 100vw;
    flex: 1;
}
#wrapper.toggled #sidebar-wrapper {
    margin-left: 0;
}
#sidebar-wrapper div li a {
    font-weight: 200;
}

/* Loading Custom */
.loading-panel-wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 999998;
    display: none;
    text-align: center;
    cursor: wait;
  }
  .loading-panel-wrapper .loading-panel-dialog {
    width: 200px;
    height: 200px;
    border-radius: 0.25em;
    top: 50%;
    margin-top: -100px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }
  .loading-panel-wrapper .progress-bar-dialog {
    width: 80%;
    height: 50px;
    border-radius: 0.25em;
    /* box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3); */
    top: 50%;
    margin-top: -25px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }
  .loading-panel-wrapper .progress-bar-dialog .progress {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border: solid 1px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 0.6);
    transition: 0s;
  }
  
  .loading-panel-wrapper .progress-bar-dialog .progress-bar {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    transition: 0s;
  }
  
  .loading-panel-wrapper .progress-bar-dialog .progress,
  .loading-panel-wrapper .progress-bar-dialog .progress-bar {
    height: 50px;
  }

.input-range__slider, .input-range__track--active {
    background: $app-primary !important;
    border: 1px solid $app-primary !important;
}

/** Check **/
.container-check {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 25px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
  
.container-check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
  
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1.5px solid #D1D1D1;
    border-radius: 5px;
}
  
.container-check:hover input ~ .checkmark {
    background-color:#FFF;
    cursor: pointer;
}
.container-check input:checked ~ .checkmark {
    background-color: $app-primary;
}
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
  
.container-check input:checked ~ .checkmark:after {
    display: block;
}
  
.container-check .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
}

.sidebar-heading {
    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}
.ver-mas {
    border-radius: 10px !important;
}

.headSearch {
    position: relative;
    input {
        width: 360px;
        height: 40px;
        border: none;
        border-radius: 10px;
        padding-right: 2.375rem;
    }
}
.fsearch {
    position: absolute;
    right: 10px;
}

/* Subir producto */
.sidebar-width {
    width: 270px !important;
}
.bg-sidebar-user {
    background-color: $app-primary !important;
}
.sidebar-items {
    color: white;
    text-decoration: none;
    font-weight: 200;
    &:hover {
        color: white;
        text-decoration: underline;
    }
}
.icon-sidebar {
    margin-right: 10px;
    margin-bottom: 5px;
}
.sidebar-items.selected {
    font-weight: bold;
    text-decoration: underline;
}
.border-bottom-sidebar {
    border-bottom: 1px solid rgba(223, 224, 235, 0.3) !important;
}
