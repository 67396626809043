// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here

// 3. Include remainder of required Bootstrap stylesheets
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";

// 4. Include any optional Bootstrap CSS as needed
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/helpers";

// 5. Optionally include utilities API last to generate classes based on the Sass map in `_utililies.scss`
@import "../node_modules/bootstrap/scss/utilities/api";

$app-primary: #061f79;
$app-secondary: #779fda66;
$app-blue: #779fda;
$app-red: #f40001;
$app-gray: $gray-400;

@import "./scss/wrapper";
@import "./scss/main";
@import "./scss/custom";
@import "./scss/font.scss";
@import "./scss/query.scss";

body, html {
  font-family: 'Helvetica Now', sans-serif;
  font-weight: 100;
  font-size: 14px;
  overflow-x: hidden;
}


/** Custom scrollbar */
body::-webkit-scrollbar, .modal::-webkit-scrollbar, .drop-notifications::-webkit-scrollbar {
  width: 10px;
}
body::-webkit-scrollbar-track, .modal::-webkit-scrollbar-track, .drop-notifications::-webkit-scrollbar-track  {
  background: transparent;
  border-radius: 8px;
}
body::-webkit-scrollbar-thumb, .modal::-webkit-scrollbar-thumb, .drop-notifications::-webkit-scrollbar-thumb {
  background: $app-gray;
}

.global {
  &-cursor {
    &-pointer {
      cursor: pointer !important;
    }
  }
  &-selected {
    border: 1px white solid;
    color: white !important;
  }
  &-index {
    z-index: -100;
  }
}



.app {
  &-title {
    color: $app-primary;
    text-transform: uppercase;
    letter-spacing: 0.15rem;
    font-weight: lighter;
  }
  &-card-title {
    color: $app-primary;
  }
  &-header {
    background-color: $app-primary;
    font-size: 16px;
    &__icons {
      margin-right: 10px;
    }
  }
  &-searcher {
    width: 100%;
    height: 90vh;
    &__container {
      padding: 20px 25px;
      background-color: rgba(119, 159, 218, 0.5);
      border-radius: 20px;
      box-sizing: border-box;
      max-width: 968px;
      width: 80%;
      z-index: 1;
      &__form {
        label {
          font-weight: bold;
          color: white;
          margin-bottom: 3px !important;
        }
        h1 {
          font-weight: 600;
          letter-spacing: 0.1em;
        }
      }
    }
    &__video {
      position: absolute;
      right: 0;
      bottom: 0;
      min-width: 100%; 
      min-height: 100%;
    }
  }
  &-carousel {
    transition: transform .2s;
    &:hover {
      box-shadow: 0 0 15px rgba(72,69,64,.5);
      transform: scale(1.01);
    }
  }
  &-footer {
    height: 500px;
    &__icons {
      font-size: 26px;
      margin-right: 10px;
      color: $app-red;
      text-align: center;
    }
    &__links {
      list-style: none;
      padding-bottom: 8px;
      a {
        text-decoration: none;
        color: $gray-400;
      }
    }
    &__title {
      color: white;
      text-align: left;
      text-transform: uppercase;
      letter-spacing: 0.15em;
      font-size: 20px;
    }
    &__top {
      img {
        height: 170px;
        width: 230px;
        margin: auto;
      }
    }
  }
  &-team {
    &__photo {
      height: 40vw;
      margin: auto;
    }
    &__photo2 {
      height: 10vw;
      margin: auto;
      img {
        object-fit: cover;
      }
    }
  }
}

.gu-mirror {
  display: none;
}

.item-drop-img {
  position: relative;
  padding: 10px;
  img {
    height: 95px;
    width: 100%;
  }
  transition: transform .2s;
  &:hover {
    box-shadow: 0 0 15px rgba(72,69,64,.5);
    transform: scale(1.01);
    cursor: all-scroll;
  }
  button {
    position: absolute;
    top: 12px;
    right: 12px;
    &:hover {
      border-color: black;
      color: black !important;
    }
  }
}

.vw-100 {
  min-width: 100vw !important;
}

.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0; // remove the gap so it doesn't close
}

#carouser-detail, .carouser-detail {
  .carousel-control-next-icon, .carousel-control-prev-icon {
    background-color: $app-gray;
    padding: 20px;
    border-radius: 5px;
  }
}