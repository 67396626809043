@font-face {
    font-family: 'Helvetica Now';
    font-display: swap;
    src: url('./../fonts/HelveticaNowText-Regular.otf') format("opentype");
}

@font-face {
    font-family: 'Helvetica Now';
    font-weight: 100;
    font-display: swap;
    src: url('./../fonts/HelveticaNowText-Light.otf') format("opentype");
}

@font-face {
    font-family: 'Helvetica Now';
    font-style: italic;
    font-display: swap;
    src: url('./../fonts/HelveticaNowText-LightItalic.otf') format("opentype");
}

@font-face {
    font-family: 'Helvetica Now';
    font-weight: 700;
    font-display: swap;
    src: url('./../fonts/HelveticaNowText-Bold.otf') format("opentype");
}