.bg {
  &-secondary {
    background-color: $app-secondary !important;
  }
  &-primary {
    background-color: $app-primary !important;
  }
}

.color {
  &-secondary {
    color: $app-secondary !important;
  }
  &-gray {
    color: $gray-600 !important;
  }
}

.btn {
  &-primary {
    background-color: $app-primary;
    border-color: $app-primary;
    &:hover, &:focus, &:active {
      background-color: white;
      border-color: $app-primary;
      color: $app-primary;
      box-shadow: none
    }
  }
  &-outline-primary {
    border-color: $app-primary;
    color: $app-primary !important;
    &:hover, &:focus, &:active {
      border-color: $app-primary !important;
      color: white !important;
      background-color: $app-primary;
    }
  }
  &-grey {
    background-color: #F5F5F5;
    &:hover {
      border-color: #F5F5F5;
      background-color: #F5F5F5;
    }
  }
  &-banner {
    right: 15px;
    top: -45px;
  }
  &-above {
    position: absolute;
    bottom: 10px;
    left: 50%;
    cursor: pointer;
    font-size: 28px;
    font-weight: bold;
  }
}
.text {
  &-primary {
    color: $app-primary !important
  }
  &-200 {
    font-weight: 200;
  }
  &-300 {
    font-weight: 300;
  }
  &-400 {
    font-weight: 600;
  }
  &-600 {
    font-weight: 600;
  }
}

.font {
  &-12 {
    font-size: 12px;
  }
  &-14 {
    font-size: 14px;
  }
  &-16 {
    font-size: 16px;
  }
  &-18 {
    font-size: 18px;
  }
  &-20 {
    font-size: 20px;
  }
  &-22 {
    font-size: 22px;
  }
  &-26 {
    font-size: 26px;
  }
  &-36 {
    font-size: 36px;
  }
  &-50 {
    font-size: 50px;
  }
  &-70 {
    font-size: 70px;
  }
}

.bg-navbar {
  background-color: rgba(119, 159, 218, 0.4);
}

.text-break-spaces {
  white-space: break-spaces !important;
}


.team {
  --webkit-transform: translate3d(0, 0, 0);
  --webkit-backface-visibility: hidden;
  .position {
    position: absolute;
    background-color: transparent;
  }
}

.navbar-light .navbar-toggler-icon {
  background-image: none !important;
}


.navbar-collapse {
  position: relative;
  height: 120px;
  >li {
    position: absolute;
  }
}